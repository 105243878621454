import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';
import { defineMessages, intlShape } from 'react-intl';
import { subscribeNewsletter } from '../../actions/user';
import s from './NewsletterPopup.css';
// import CheckBox from '../CheckBox';
// import TextBox from '../TextBox';
// import Button from '../Button';
import { OPEN_NEWSLETTER_MODAL, CLOSE_NEWSLETTER_MODAL } from '../../constants';

const BGCImg = require('./bgc.webp');
const hotelImgDesk = require('./toreward_desktop.webp');
const hotelImgMob = require('./toreward_responsive.webp');

const disableNewsletterPopupWhenIdleFor = 100 * 60 * 60;

const messages = defineMessages({
  join: {
    id: 'newsletter.join',
    defaultMessage: 'JOIN',
    description: 'Join',
  },
  our: {
    id: 'newsletter.our',
    defaultMessage: 'OUR',
    description: 'Our',
  },
  planet: {
    id: 'newsletter.planet',
    defaultMessage: 'PLANET',
    description: 'Planet',
  },
  message: {
    id: 'newsletter.message',
    defaultMessage:
      "Leave your email address here. We'll send you the latest deals and specials twice a week, so you will be the first to know.",
    description: 'Message',
  },
  email: {
    id: 'newsletter.email',
    defaultMessage: 'Your email address',
    description: 'Email address field',
  },
  button: {
    id: 'newsletter.button',
    defaultMessage: 'SIGN ME UP',
    description: 'Sign me up button',
  },
  close: {
    id: 'newsletter.close',
    defaultMessage: "No thanks, I'm just looking around",
    description: 'Alternative close button',
  },
});

class NewsletterPopup extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    closeModal: PropTypes.func,
    subscribeNewsletter: PropTypes.func,
    source: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  };

  // eslint-disable-next-line react/static-property-placement
  static contextTypes = {
    intl: intlShape,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    source: '',
    closeModal: () => {},
    subscribeNewsletter: () => {},
    isLoggedIn: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      lang: {
        english: true,
        japanese: false,
        bahasa: false,
        thai: false,
      },
      email: '',
      langLabel: 'english',
    };

    this.onClickLang = this.onClickLang.bind(this);
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.bindOnKeydown = this.onKeydown.bind(this);
      window.addEventListener('keydown', this.bindOnKeydown);
    }
  }

  onClickLang(e) {
    this.state.lang = {
      english: false,
      japanese: false,
      bahasa: false,
      thai: false,
    };
    this.state.lang[e.name] = !this.state.lang[e.name];
    this.state.langLabel = e.name;
    this.setState(prevState => ({ lang: prevState.lang }));
  }

  onCloseModal = () => {
    this.props.closeModal();
    window.removeEventListener('keydown', this.bindOnKeydown);
    if (!this.props.isLoggedIn && window.localStorage) {
      window.localStorage.setItem('newsletter', new Date().getTime());
    }
  };

  onKeydown(event) {
    if (event.keyCode === 27) {
      this.onCloseModal();
    }
  }

  onTypeEmail = email => {
    this.setState({ email });
  };

  onClickSubmit = () => {
    this.props.subscribeNewsletter(
      this.state.email,
      this.state.langLabel,
      this.props.source,
    );
    this.setState({ email: '', langLabel: 'english' });
    this.onCloseModal();
  };

  render() {
    const { lang, email } = this.state;
    return (
      <div className={cx(s.root)}>
        <div className={s.body}>
          <div className={s.content}>
            {/* <div className={s.tagLine}>
              <img alt="Red Planet Hotel BGC The Fort" src={BGCImg} />
              <button>BOOK NOW</button>
            </div> */}
            <div className={s.hotelContainer}>
              <button
                type="button"
                className={s.closeButton}
                onClick={this.onCloseModal}
              >
                ×
              </button>
              <img
                className={s.hotelImgDesk}
                alt="Red Planet Hotel BGC The Fort"
                src={hotelImgDesk}
                style={{ objectFit: 'cover' }}
              />
              <img
                className={s.hotelImgMob}
                alt="Red Planet Hotel BGC The Fort"
                src={hotelImgMob}
                style={{ objectFit: 'cover' }}
              />
              {/* <div className={s.textContainer}>
                <div>GRAND OPENING</div>
                <div>MAY 30</div>
              </div> */}
            </div>
            {/* <div className={s.freddy} /> */}
            {/* <div className={s.web}>
              <h3>
                <div>JOIN</div>
                <div>OUR</div>
                <div>PLANET</div>
              </h3>
              <div className={s.text}>
                <p>{this.context.intl.formatMessage(messages.message)}</p>
              </div>
              <div className={cx(s.lang, s.margin)}>x
                <div className={s.box}>
                  <CheckBox
                    name="english"
                    className={s.checkbox}
                    isChecked={lang.english}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>English</div>
                </div>
                <div className={s.box}>
                  <CheckBox
                    name="thai"
                    className={s.checkbox}
                    isChecked={lang.thai}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>Thai</div>
                </div>
              </div>
            </div>
            <div className={s.mobile}>
              <h4>
                <span>JOIN </span>OUR PLANET
              </h4>
              <div className={s.text}>
                <p>{this.context.intl.formatMessage(messages.message)}</p>
              </div>
              <div className={cx(s.lang, s.margin)}>
                <div className={s.box}>
                  <CheckBox
                    name="english"
                    className={s.checkbox}
                    isChecked={lang.english}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>English</div>
                </div>
                <div className={s.box}>
                  <CheckBox
                    name="japanese"
                    className={s.checkbox}
                    isChecked={lang.japanese}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>Japanese</div>
                </div>
                <div className={s.box}>
                  <CheckBox
                    name="bahasa"
                    className={s.checkbox}
                    isChecked={lang.bahasa}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>Bahasa</div>
                </div>
                <div className={s.box}>
                  <CheckBox
                    name="thai"
                    className={s.checkbox}
                    isChecked={lang.thai}
                    onChange={this.onClickLang}
                  />
                  <div className={s.checkboxLabel}>Thai</div>
                </div>
              </div>
            </div>
            <div className={cx(s.margin, s.email)}>
              <TextBox
                errorMessage="error mail"
                errorBorderColor="#ffff00"
                inputType="email"
                placeholder={this.context.intl.formatMessage(messages.email)}
                onChange={this.onTypeEmail}
                value={email}
              />
            </div>
            <Button
              className={cx(s.button, s.margin)}
              onClick={this.onClickSubmit}
              label={this.context.intl.formatMessage(messages.button)}
            />
            <button
              type="button"
              onClick={this.onCloseModal}
              className={cx(s.closeText, s.margin)}
            >
              {this.context.intl.formatMessage(messages.close)}
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export function openModal(idleUser) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!window.localStorage || user.openNewsletterModal) {
      return;
    }
    const lastOpenForIdle = window.localStorage.getItem('newsletter');
    const openForIdleGuest =
      !user.isLoggedIn &&
      (!lastOpenForIdle ||
        (lastOpenForIdle &&
          new Date().getTime() - lastOpenForIdle >=
            disableNewsletterPopupWhenIdleFor));
    const fromFooter = !idleUser || (!idleUser && user.isLoggedIn);

    if (openForIdleGuest || fromFooter) {
      dispatch({
        type: OPEN_NEWSLETTER_MODAL,
        payload: {
          source: idleUser ? 'idle_popup' : 'footer',
        },
      });
      window.localStorage.removeItem('newsletter');
    }
  };
}

function closeModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_NEWSLETTER_MODAL,
    });
  };
}

const mapDispatch = {
  subscribeNewsletter,
  closeModal,
};

const mapState = state => ({
  source: state.user.newsLetterSource,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapState, mapDispatch)(withStyles(s)(NewsletterPopup));
